<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <a v-if="$route.params.configId && $route.params.cznName && $route.params.quarter && $route.params.year"
             @click="$router.go(-1)" class="back-link">
            <v-icon color="#0033A0">mdi-chevron-left</v-icon>
            Назад
          </a>
          <h1>Опросы
<!--            <CznUserInfo :change-callback="behalUserChangeCallback"></CznUserInfo>-->

            <div class="header-buttons">
              <div :class="{ active: isActive }" class="menu-button search-menu-button" @click="isActive = !isActive">
                <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M9.5 3C11.2239 3 12.8772 3.68482 14.0962 4.90381C15.3152 6.12279 16 7.77609 16 9.5C16 11.11 15.41 12.59 14.44 13.73L14.71 14H15.5L20.5 19L19 20.5L14 15.5V14.71L13.73 14.44C12.59 15.41 11.11 16 9.5 16C7.77609 16 6.12279 15.3152 4.90381 14.0962C3.68482 12.8772 3 11.2239 3 9.5C3 7.77609 3.68482 6.12279 4.90381 4.90381C6.12279 3.68482 7.77609 3 9.5 3ZM9.5 5C7 5 5 7 5 9.5C5 12 7 14 9.5 14C12 14 14 12 14 9.5C14 7 12 5 9.5 5Z"
                      fill="#0033A0"/>
                </svg>
              </div>
              <div class="search-panel-wrap">
                <div class="search-panel">
                  <div class="wrap">
                    <a class="close" @click="isActive=false">
                      <svg fill="none" height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                            fill="#0033A0"/>
                      </svg>
                    </a>
                    <h1>Поиск</h1>
                    <v-row>
                      <v-col cols="12" lg="3">
                        <v-text-field label="№ заявления"></v-text-field>
                      </v-col>

                      <v-col cols="12" lg="3">
                        <v-text-field label="Электронная почта"></v-text-field>
                      </v-col>

                      <v-col cols="12" lg="3">
                        <div class="date-range-label">Дата запроса</div>
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="date"
                            min-width="auto"
                            offset-y
                            transition="scale-transition"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="date"
                                v-bind="attrs"
                                v-on="on"
                                append-icon="mdi-calendar"
                                label="С"
                                readonly
                            ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="date"
                              no-title
                              scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                text
                                @click="menu = false"
                            >
                              Отмена
                            </v-btn>
                            <v-btn
                                color="primary"
                                text
                                @click="$refs.menu.save(date)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="12" lg="3">
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="date"
                            min-width="auto"
                            offset-y
                            transition="scale-transition"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="date"
                                v-bind="attrs"
                                v-on="on"
                                append-icon="mdi-calendar"
                                label="По"
                                readonly
                            ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="date"
                              no-title
                              scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                text
                                @click="menu = false"
                            >
                              Отмена
                            </v-btn>
                            <v-btn
                                color="primary"
                                text
                                @click="$refs.menu.save(date)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>

                    <div class="button-wrap">
                      <button class="btn btn-primary" @click="isActive=false">применить</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </h1>

          <department-info
              v-if="!$route.params.configId && !$route.params.cznName && !$route.params.quarter && !$route.params.year"
              @depObj="department = $event; initializeData()"
          ></department-info>

          <template
              v-if="!$route.params.configId && !$route.params.cznName && !$route.params.quarter && !$route.params.year">
            <div v-if="activeConfigs"
                 class="czn-link">
              <div>Ссылки на анкетирования, проводящиеся на данный момент:</div>
              <table>
                <tr v-for="(config, configIndex) of activeConfigs" :key="config.id">
                  <td>{{ config.anket.title }}</td>
                  <td><a :href="formLink(config.id, configIndex)" target="_blank">{{ cznLink[configIndex] }}</a></td>
                  <td>
                    <v-btn outlined @click="copyLink(configIndex)" title="Скопировать в буфер обмена">
                      <v-icon size="16px">mdi-content-copy</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </table>
            </div>
            <div v-else class="czn-link">
              <div>На данный момент анкетирование не проводится</div>
            </div>
          </template>

          <h3 style="font-weight: 600; color: #1C1C1F;">Реестр заполненных анкет</h3>
          <table class="anketsRegister-table" style="width: 100%">
            <thead>
            <tr>
              <th>№ анкетируемого / E-mail</th>
              <th>Анкетирование</th>
              <!--              <th>Комментарий</th>-->
              <!--              <th>Результат приема</th>-->
              <th>Дата</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="anket in anketFormDatas" :key="'sd7f'+anket.id">
              <td>
                {{ anket.regnumber }}<br>{{ anket.email }}
              </td>
              <td>
                <router-link :to="'/ankets/anketInfo/'+anket.id">
                  {{ strTrim(getAnketConfig(anket.configId).title) }}
                </router-link>
              </td>
              <!--              <td>-->
              <!--                &mdash;-->
              <!--              </td>-->
              <!--              <td>-->
              <!--                &mdash;-->
              <!--              </td>-->
              <td>
                {{ anket.localDateTime | dateTimeFormat }}
              </td>
              <td><!-- TODO на прод поставить правильную ссылку на фронт с акентами 'https://new.profczn.ru/' -->
<!--                <a :href="'https://ankets.testczn.nekadid.ru/'+anket.configId" target="_blank" style="text-decoration: none">-->
                <a :href="'https://new.profczn.ru/'+anket.configId" target="_blank" style="text-decoration: none">
                  <v-icon color="#0033A0">mdi-briefcase-eye-outline</v-icon>
                </a>
                <!--                <div class="edit-menu">-->
                <!--                  <div class="edit-menu-icon">-->
                <!--                    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">-->
                <!--                      <path-->
                <!--                          d="M12 2C13.3132 2 14.6136 2.25866 15.8268 2.7612C17.0401 3.26375 18.1425 4.00035 19.0711 4.92893C19.9997 5.85752 20.7362 6.95991 21.2388 8.17317C21.7413 9.38642 22 10.6868 22 12C22 14.6522 20.9464 17.1957 19.0711 19.0711C17.1957 20.9464 14.6522 22 12 22C10.6868 22 9.38642 21.7413 8.17317 21.2388C6.95991 20.7362 5.85752 19.9997 4.92893 19.0711C3.05357 17.1957 2 14.6522 2 12C2 9.34784 3.05357 6.8043 4.92893 4.92893C6.8043 3.05357 9.34784 2 12 2ZM12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4ZM12 10.5C12.3978 10.5 12.7794 10.658 13.0607 10.9393C13.342 11.2206 13.5 11.6022 13.5 12C13.5 12.3978 13.342 12.7794 13.0607 13.0607C12.7794 13.342 12.3978 13.5 12 13.5C11.6022 13.5 11.2206 13.342 10.9393 13.0607C10.658 12.7794 10.5 12.3978 10.5 12C10.5 11.6022 10.658 11.2206 10.9393 10.9393C11.2206 10.658 11.6022 10.5 12 10.5ZM7.5 10.5C7.89782 10.5 8.27936 10.658 8.56066 10.9393C8.84196 11.2206 9 11.6022 9 12C9 12.3978 8.84196 12.7794 8.56066 13.0607C8.27936 13.342 7.89782 13.5 7.5 13.5C7.10218 13.5 6.72064 13.342 6.43934 13.0607C6.15804 12.7794 6 12.3978 6 12C6 11.6022 6.15804 11.2206 6.43934 10.9393C6.72064 10.658 7.10218 10.5 7.5 10.5ZM16.5 10.5C16.8978 10.5 17.2794 10.658 17.5607 10.9393C17.842 11.2206 18 11.6022 18 12C18 12.3978 17.842 12.7794 17.5607 13.0607C17.2794 13.342 16.8978 13.5 16.5 13.5C16.1022 13.5 15.7206 13.342 15.4393 13.0607C15.158 12.7794 15 12.3978 15 12C15 11.6022 15.158 11.2206 15.4393 10.9393C15.7206 10.658 16.1022 10.5 16.5 10.5Z"-->
                <!--                          fill="#0033A0"/>-->
                <!--                    </svg>-->
                <!--                  </div>-->
                <!--                  <div class="edit-menu-popup">-->

                <!--                    <router-link :to="'/ankets/anketInfo/'+ anket.id">-->
                <!--                      <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">-->
                <!--                        <path-->
                <!--                            d="M10 12C12.21 12 14 10.21 14 8C14 5.79 12.21 4 10 4C7.79 4 6 5.79 6 8C6 10.21 7.79 12 10 12ZM10 6C11.11 6 12 6.9 12 8C12 9.1 11.11 10 10 10C8.89 10 8 9.11 8 8C8 6.89 8.9 6 10 6ZM9.27 20H2V17C2 14.33 7.33 13 10 13C11.04 13 12.5 13.21 13.86 13.61C13 13.95 12.2 14.42 11.5 15C11 14.94 10.5 14.9 10 14.9C7.03 14.9 3.9 16.36 3.9 17V18.1H9.22C9.2 18.15 9.17 18.2 9.14 18.25L8.85 19L9.14 19.75C9.18 19.83 9.23 19.91 9.27 20ZM17 18C17.56 18 18 18.44 18 19C18 19.56 17.56 20 17 20C16.44 20 16 19.56 16 19C16 18.44 16.44 18 17 18ZM17 15C14.27 15 11.94 16.66 11 19C11.94 21.34 14.27 23 17 23C19.73 23 22.06 21.34 23 19C22.06 16.66 19.73 15 17 15ZM17 21.5C15.62 21.5 14.5 20.38 14.5 19C14.5 17.62 15.62 16.5 17 16.5C18.38 16.5 19.5 17.62 19.5 19C19.5 20.38 18.38 21.5 17 21.5Z"-->
                <!--                            fill="#0033A0"/>-->
                <!--                      </svg>-->
                <!--                      Посмотреть-->
                <!--                    </router-link>-->

                <!--                    <v-dialog-->
                <!--                        v-model="dialog"-->
                <!--                        max-width="600px"-->
                <!--                        persistent-->
                <!--                    >-->
                <!--                      <template v-slot:activator="{ on, attrs }">-->
                <!--                        <a v-bind="attrs"-->
                <!--                           v-on="on"-->
                <!--                           href="#"-->
                <!--                        >-->
                <!--                          <svg fill="none" height="24" viewBox="0 0 24 24" width="24"-->
                <!--                               xmlns="http://www.w3.org/2000/svg">-->
                <!--                            <path-->
                <!--                                d="M18.13 12L19.39 10.74C19.83 10.3 20.39 10.06 21 10V9L15 3H5C3.89 3 3 3.89 3 5V19C3 20.1 3.89 21 5 21H11V19.13L11.13 19H5V5H12V12H18.13ZM14 4.5L19.5 10H14V4.5ZM19.13 13.83L21.17 15.87L15.04 22H13V19.96L19.13 13.83ZM22.85 14.19L21.87 15.17L19.83 13.13L20.81 12.15C21 11.95 21.33 11.95 21.53 12.15L22.85 13.47C23.05 13.67 23.05 14 22.85 14.19Z"-->
                <!--                                fill="#0033A0"/>-->
                <!--                          </svg>-->
                <!--                          Редактировать-->
                <!--                        </a>-->
                <!--                      </template>-->
                <!--                      <AnketInfoEditDialog @close="dialog=false"/>-->
                <!--                    </v-dialog>-->
                <!--                  </div>-->
                <!--                </div>-->
              </td>
            </tr>
            <tr v-if="lastPortionLoaded">
              <td colspan="6">
                <a href="#" @click.prevent="loadAnketFormData">Загрузить еще</a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>
</template>

<script>
// import AnketInfoEditDialog from './dialogs/AnketInfoEditDialog';
// import CznUserInfo from "./elements/CznUserInfo";
import DepartmentInfo from "@/components/elements/DepartmentInfo";

export default {
  name: 'AnketsRegister',
  props: {},
  components: {
    // CznUserInfo,
    DepartmentInfo,
  },
  data() {
    return {
      // dialog: false,
      activeConfigs: [],
      cznLink: [],
      isActive: false,
      department: null,
      anketFormDatas: [],
      apiLoaded: false,
      errorText: null,
      anketFormDataPage: 0,
      date: null,
      menu: null,
      lastPortionLoaded: false,
    };
  },

  filters: {
    dateTimeFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {
        timeZone: 'Europe/Moscow',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },

  methods: {
    setUserInitialParams() {
      if (this.$user.role === 'ROLE_CZN_TERRITORIAL' || this.$user.role === 'ROLE_CZN_MANAGER') {
        this.userRegion = this.$user.region;
        this.department = this.$user.department;
      } else if (this.$user.role === 'ROLE_REGIONAL_AGENCY') {
        this.userRegion = this.$user.region;
        // Находим регион пользователя в $cznDepartmentsByRegionList для того, чтобы из него получить список ЦЗН региона
        let region = this.$cznDepartmentsByRegionList.filter((region) => {return region.name === this.userRegion.name})
        this.department = region[0].departments[0];
      } else { //для админов и ВНИИ Труда
        this.userRegion = this.$cznDepartmentsByRegionList[0];
        this.department = this.userRegion.departments[0];
      }
    },

    async initializeData() {
      this.anketFormDatas = [];
      this.anketFormDataPage = 0;
      let p1 = this.loadAnketFormData(),
          p2 = this.loadAnketConfigs();
      await p1;
      await p2;
      this.formLink();
    },

    async loadAnketFormData() {
      let req;
      if (this.$route.params.configId && this.$route.params.cznName && this.$route.params.quarter && this.$route.params.year) {
        req = await this.$getApi("/ankets/getAnketFormDataByCzn",
            {configId: this.$route.params.configId, department: this.$route.params.cznName,
          quarter: this.$route.params.quarter, year: this.$route.params.year})
      } else {
        req = await this.$getApi("/ankets/getAnkets", {userName: this.department.id, page: this.anketFormDataPage})
      }
      if (req.ok) {
        this.anketFormDatas = this.anketFormDatas.concat(req.payload)
        this.anketFormDatas.reverse();
        this.lastPortionLoaded = req.payload.length === 100
        this.anketFormDataPage++

      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    async loadAnketConfigs() {
      let req = await this.$getApi("/ankets/getAllConfigs", {userName: this.department.id, page: this.anketFormDataPage})
      if (req.ok) {
        this.configs = req.payload
        for (let c of this.configs) {
          c.anket = JSON.parse(c.anketJson)
        }
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    async getActiveConfigs() {
      let req = await this.$getApi("/ankets/getActiveConfigs")
      if (req.ok) {
        this.activeConfigs = req.payload;
        // console.log('this.activeConfig', this.activeConfigs)
        if (this.activeConfigs !== null ||  this.activeConfigs !== []) {
          for (let c of this.activeConfigs) {
            c.anket =  JSON.parse(c.anketJson)
          }
        }
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    formLink(activeConfigId, index) {
      //TODO на прод поставить правильную ссылку на фронт с акентами 'https://new.profczn.ru/'
      // this.cznLink[index] = `https://ankets.testczn.nekadid.ru/${activeConfigId}?id=${this.department.id}`;
      this.cznLink[index] = `https://new.profczn.ru/${activeConfigId}?id=${this.department.id}`;
      return this.cznLink[index]
    },

    copyLink(index) {
      navigator.clipboard.writeText(this.cznLink[index]);
    },

    getAnketConfig(id) {
      for (let c of this.configs) {
        if (c.id === id)
          return c.anket
      }
    },

    strTrim(s) {
      let maxlen = 45
      if (s.length > maxlen) {
        return s.substring(0, maxlen - 3) + "..."
      }
      return s
    },
  },

  async beforeMount() {
    this.setUserInitialParams();
    let p1 = this.loadAnketFormData(),
        p2 = this.loadAnketConfigs(),
        p3 = this.getActiveConfigs();
    await p1;
    await p2;
    await p3;
    this.apiLoaded = true
  },
};
</script>

<style lang="scss">
@import "../styles/main.scss";
</style>

<style lang="scss" scoped>
.czn-link {
  margin-top: 16px;
  margin-bottom: 35px;
  font-size: 16px;
  div {
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    color: #1C1C1F;
    //margin-bottom: 5px;
  }
  table {
    width: 80%;
    margin-left: 35px;
    td {
      text-align: left;
      vertical-align: middle;
      padding: 5px;
      &:last-child {
        padding-right: 0;
        width: 40px;
      }
      &:nth-child(2) {
        text-align: right;
      }
    }
  }
  .v-btn{
    margin-left: 8px;
    max-height: 30px;
    min-width: 30px;
    padding: 0 2px;
  }
}
</style>
